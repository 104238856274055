// VALIDATION
const $form = document.querySelector('form');

function handleSubmit(e) {
    e.preventDefault();
    let valid = true;
    const req = this.querySelectorAll('[required]');
    for (let i = 0; i < req.length; i++) {
        const $field = req[i];
        if ($field.value.trim().length < 1) {
            valid = false;
            const $alert = $field.parentElement.querySelector('.text-danger');
            $alert.classList.remove('d-none');
            $field.classList.add('is-invalid');
            $field.removeEventListener('keyup', onInputKeyup.bind($field, $field, $alert));
            $field.addEventListener('keyup', onInputKeyup.bind($field, $field, $alert));
        }
    }
    if (!valid) return false;
    this.submit();
}

function onInputKeyup($field, $alert) {
    if (this.value.trim().length > 0) {
        $alert.classList.add('d-none');
        $field.classList.remove('is-invalid');
    } else {
        $alert.classList.remove('d-none');
        $field.classList.add('is-invalid');
    }
}

// LOGIN FOCUS
const $identifier = $form.querySelector('#identifier');
const $password = $form.querySelector('#password');
if ($identifier.value.trim().length > 0) {
    $password.focus();
} else {
    $identifier.focus();
}

$form.addEventListener('submit', handleSubmit);
