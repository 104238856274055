/* eslint import/no-unresolved: 0 */

// Bootstrap
import 'popper.js';
import 'bootstrap/util';
import 'bootstrap/dropdown';
import 'bootstrap/collapse';
import toast from 'izitoast';

/**
 * Toast shortcut functions
 */
(function () {
    window.toast = {
        success: message => {
            toast.success({
                title: 'Success',
                icon: 'fas fa-check-circle',
                displayMode: 2,
                message,
            });
        },
        error: message => {
            toast.error({
                title: 'Error',
                icon: 'fas fa-exclamation-circle',
                displayMode: 2,
                message,
            });
        },
        warning: message => {
            toast.warning({
                title: 'Warning',
                icon: 'fas fa-exclamation-triangle',
                displayMode: 2,
                message,
            });
        },
        trackInfo: (artist, title) => {
            toast.show({
                position: 'bottomCenter',
                icon: 'fas fa-volume-up',
                theme: 'dark',
                displayMode: 2,
                message: `${artist} - ${title}`,
            });
        },
    };
}());

// Polyfills
// import './_polyfills/fetch';
